exports.components = {
  "component---src-pages-ai-driven-incentives-js": () => import("./../../../src/pages/ai_driven-incentives.js" /* webpackChunkName: "component---src-pages-ai-driven-incentives-js" */),
  "component---src-pages-blogs-ten-x-and-its-four-pillars-js": () => import("./../../../src/pages/blogs/tenX_and_its_four_pillars.js" /* webpackChunkName: "component---src-pages-blogs-ten-x-and-its-four-pillars-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-highlight-js": () => import("./../../../src/pages/highlight.js" /* webpackChunkName: "component---src-pages-highlight-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-subscribe-old-js": () => import("./../../../src/pages/subscribe-old.js" /* webpackChunkName: "component---src-pages-subscribe-old-js" */),
  "component---src-pages-thought-leadership-js": () => import("./../../../src/pages/thought-leadership.js" /* webpackChunkName: "component---src-pages-thought-leadership-js" */),
  "component---src-templates-wppost-js": () => import("./../../../src/templates/wppost.js" /* webpackChunkName: "component---src-templates-wppost-js" */)
}

